/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.container {
  @apply flex flex-col gap-4;
}

.title {
  @apply font-semibold text-2xl;
}

.card {
  @apply flex flex-col items-center gap-6;
  @apply bg-base-100 rounded-2xl;
  @apply px-5 py-6;
  @apply overflow-hidden;
}

.card h2 {
  @apply font-semibold text-xl m-0;
}

.level-container {
  @apply w-full;
  @apply flex flex-col gap-4;
}

.level-title {
  @apply flex justify-between items-center;
}

.level-stepper {
  @apply w-full;
}

.activity-container {
  @apply w-full;
}
