/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.container {
  @apply flex flex-col max-w-[600px];
}

.row {
  @apply flex justify-between gap-3;
  @apply mt-2;
}

.buttons {
  @apply flex gap-2;
}

.table .ant-table-tbody {
  @apply max-h-[400px];
}
