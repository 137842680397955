/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.button {
  @apply w-[36px] h-[36px];
  @apply flex items-center justify-center;
  @apply bg-base-90 text-text-60 rounded-full;
  @apply m-1;
  @apply transition-colors;
}

.button:hover {
  @apply bg-base-60 text-text-10;
}

.button:disabled {
  @apply opacity-40;
}
