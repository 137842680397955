/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply flex-1;
  @apply flex flex-col gap-6;
}

.fields {
  @apply flex flex-col gap-3;
}

.actions {
  @apply grid grid-cols-2 gap-3;
}
