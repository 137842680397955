/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply relative;
  @apply bg-base-100 rounded-2xl;
  @apply px-5 py-6;
  @apply flex flex-col  gap-y-6;
  @apply overflow-hidden;
}

.header {
  @apply w-full;
  @apply flex flex-col items-start gap-2;
}

.header > div {
  @apply px-5 py-1 text-base;
}

.svg {
  @apply inline -mt-1 -ml-1;
}

.user {
  @apply flex flex-row items-center justify-between;
  @apply border border-base-80 rounded-xl;
  @apply w-full;
  @apply px-3 py-2;
}

.userId {
  @apply bg-primary-100/20 rounded-2xl;
  @apply px-3 py-2;
  @apply text-xs;
  @apply font-medium;
  @apply flex items-center justify-between gap-3;
}

.userId button {
  @apply text-primary-100/80;
}

.btn {
  @apply justify-between;
}

.body {
  @apply grid grid-cols-1 gap-2;
  @apply w-full;
}

.body_group {
  @apply grid grid-cols-2 gap-2;
  @apply w-full;
}

.body_group > *:only-child {
  @apply col-span-full;
}
