/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.container {
  @apply flex flex-col gap-4;
}

.title {
  @apply font-semibold text-2xl;
}

.card {
  @apply flex flex-col items-center gap-6;
  @apply bg-base-100 rounded-2xl;
  @apply px-5 py-6;
  @apply overflow-hidden;
}

.card h2 {
  @apply font-semibold text-xl m-0;
}

.data-fields-container {
  @apply flex flex-wrap gap-2;
  @apply w-full;
}

.data-field-item {
  @apply grow shrink-0;
  flex-basis: calc(33.33% - 8px);
}

.data-field-doc-item {
  @apply grow shrink-0;
  flex-basis: calc(50% - 8px);
}

.document-container {
  @apply flex flex-col gap-4;
  @apply w-full;
}

.slider-container {
  @apply w-full h-[200px];
}
