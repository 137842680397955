/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply flex flex-row items-stretch gap-x-6;
}

.plane {
  @apply relative w-full;
  @apply bg-base-100 rounded-2xl;
  @apply px-5 py-6 mb-6;
  @apply flex flex-col  gap-y-6;
  @apply overflow-hidden;
}

.chart_section {
  @apply flex gap-4;
  @apply flex-wrap;
}

.chart {
  @apply relative;
  @apply flex-1;
  @apply h-[220px];
  @apply min-w-[210px];
}

.table {
  @apply w-full;
  @apply flex-1 min-w-[300px];
  flex-grow: 2;
}
