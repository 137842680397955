/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.list {
  @apply flex flex-col gap-1;
  @apply w-80 max-h-96;
  @apply overflow-auto;
}

.spinner {
  @apply flex justify-center items-center;
  @apply w-80 h-96;
}

.container {
  @apply flex flex-col gap-3;
}

.title {
  @apply text-xl font-bold;
}

.card {
  @apply flex flex-col gap-[2px];
  @apply rounded-lg py-2 px-3;
  @apply bg-base-90;
}

.card-row {
  @apply flex justify-between items-center;
  @apply w-full;
}

.card-value {
  @apply text-xs;
}

.card-label {
  @apply text-xs font-semibold;
}
